import { Message } from "element-ui"; //项目已经全局引入element的话可以不单独引入
import router from "@/router";
import { encryptCode, decryptCode } from "@/utils/function";
import { uploadSchoolLogoFilePath, request_sign } from "@/utils/const";
import API from '@/api/manager/trialApplication/trialApplication.js';
import { nprogressStart, nprogressClose } from "@/plugins/nprogress.js";
export default {
    // 页面名称
    name: "trialApplication",
    inject: ['reload'],
    data() 
    {
        return {
            status:'0',
            state: '1',
            stateList: [
                {
                    id: '0',
                    name: '拒绝'
                },
                {
                    id: '1',
                    name: '申请中'
                },
                {
                    id: '2',
                    name: '批准'
                },
            ],
            app_id: '',
            fileList: [],
            groupFilePath: '',
            uploadUrl: process.env.VUE_APP_DOMAIN_URL + "/backend/public/index.php/group/uploadExportGroupFile",
            radio: '1',
            expList: [],
            keywordInfo: {
                cid: '',
                content: '',
                exp_id: '',
                id: '',
                keyword: '',
                step: '',
            },
            cid: '',
            step: '',
            exp_id: '',
            input_key: '',
            keyword: '',
            sign: '',
            infoVisible: false,
            infoTitle: '',
            // 列表数据
            schoolList: [],
            // 数据总数
            schoolListTotal: 0,
            // 根据内容搜索
            input: "",
            // 当前页码数
            page: 1,
            // 当前一页显示多少条数据
            pageSize: 10,

            activeName: "first",
        }
    },
    /**
     * @name: 生命周期创建
     * @desc: 页面创建时获取数据
     * @author: lxy
     * @date: 20210908
     */
    created() 
    {
        let self = this;
        if (this.$router.currentRoute.query.params) 
        {
            let params = JSON.parse(decryptCode(this.$router.currentRoute.query.params));
            self.page = Number(params?.page);
            self.input = params?.search;
            self.state = params?.state ? params?.state : '1';
        }
        self.getData();
    },
    methods:
    {
        handleTabClick()
        {
            this.getData();
        },
        /**
         * @name: 上传前校验
         * @author: camellia
         * @date: 2021-03-05
         */
        markHandleBeforeUpload(file) 
        {
            const self = this;
            var array = file.name.split(".");
            if (array.length < 2) 
            {
                self.fileList = [];
                // Message.error("文件错误！");
                self.$Tips({
                    // 消息提示内容
                    message: '文件错误',
                    // 消息提示类型（success-成功,warning-警告/失败）
                    messageType: 'warning',
                    // 操作按钮组（type:按钮类型（0-灰色按钮，1-蓝色按钮），text:按钮文字）
                    button: [
                        {
                            type: 1,
                            text: '知道了'
                        }
                    ],
                })
                return;
            }
            var ext = array[array.length - 1];
            if (ext != "xls" && ext != "xlsx") 
            {
                self.fileList = [];
                // Message.error("文件格式为xls，xlsx，请重试！");
                self.$Tips({
                    // 消息提示内容
                    message: '文件格式为xls，xlsx，请重试',
                    // 消息提示类型（success-成功,warning-警告/失败）
                    messageType: 'warning',
                    // 操作按钮组（type:按钮类型（0-灰色按钮，1-蓝色按钮），text:按钮文字）
                    button: [
                        {
                            type: 1,
                            text: '知道了'
                        }
                    ],
                })
                return;
            }
            const isLt2M = file.size / 1024 / 1024 < 5;
            if (!isLt2M) 
            {
                self.fileList = [];
                // Message.error("上传头像图片大小不能超过 5MB!");
                self.$Tips({
                    // 消息提示内容
                    message: '上传头像图片大小不能超过 5MB!',
                    // 消息提示类型（success-成功,warning-警告/失败）
                    messageType: 'warning',
                    // 操作按钮组（type:按钮类型（0-灰色按钮，1-蓝色按钮），text:按钮文字）
                    button: [
                        {
                            type: 1,
                            text: '知道了'
                        }
                    ],
                });return;
            }
        },
        /**
         * @name: 上传文件数量校验
         * @author: camellia
         * @date: 2021-03-05
         * @param:	file	json	上传的文件对象
         * @param:	fileList	json	现存的文件列表
         */
        handleExceed(files, fileList) 
        {
            let self = this;
            self.$Tips({
                // 消息提示内容
                message: `当前限制选择 1 个文件，本次选择了 ${
                    files.length
                    } 个文件，共选择了 ${files.length + fileList.length} 个文件`,
                // 消息提示类型（success-成功,warning-警告/失败）
                messageType: 'warning',
                // 操作按钮组（type:按钮类型（0-灰色按钮，1-蓝色按钮），text:按钮文字）
                button: [
                    {
                        type: 1,
                        text: '知道了'
                    }
                ],
            })
        },
        /**
         * @name: 上传学生excel成功
         * @author: camellia
         * @date: 2021-03-01
         * @param:	res	json	上传图片返回信息
         */
        markHandleAvatarSuccess(res) 
        {
            const self = this;
            if (res.code > 0) 
            {
                self.groupFilePath = res.path;
                // Message.success(res.msg);
                this.$Tips({
                    // 消息提示内容
                    message: res.msg,
                    // 消息提示类型（success-成功,warning-警告/失败）
                    messageType: 'success',
                    // 延时时间
                    displayTime: 1500
                })
            } 
            else 
            {
                this.$Tips({
                    // 消息提示内容
                    message: res.msg,
                    // 消息提示类型（success-成功,warning-警告/失败）
                    messageType: 'warning',
                    // 延时时间
                    displayTime: 1500
                })
            }
        },
        /**
         * @name: 下载导入成绩模板
         * @author: camellia
         * @date: 2021-03-08
         */
        downloadImportTemplate() 
        {
            window.open(
                process.env.VUE_APP_DOMAIN_URL +
                "/backend/public/uploads/template/试用申请学生名单模板.xlsx"
            );
        },
        /**
         * @name: 分页序号递增
         * @author: lxy
         * @date: 20220507
         */
        indexMethod(index) 
        {
            let currentPage = this.page; // 当前页码
            let pageSize = this.pageSize; // 每页条数
            return (index + 1) + (currentPage - 1) * pageSize; // 返回表格序号
        },
        /**
         * @name: 添加关键词
         * @author: lxy
         * @date: 2020-12-29 16:38:39
         */
        addSchool(row) 
        {
            this.keywordInfo.cid = '';
            this.keywordInfo.exp_id = '';
            this.keywordInfo.keyword = '';
            this.keywordInfo.step = '';
            this.keywordInfo.id = '';
            this.keywordInfo.content = '';
            this.infoTitle = '添加关键词'
            this.infoVisible = true;
            this.sign = 'add';
        },
        /**
         * @name: 点击分页
         * @author: lxy
         * @date: 2020-12-29 16:38:39
         */
        handleCurrentChange(val) 
        {
            const self = this;
            self.page = val;
            self.getData();
        },
        /**
         * @name: 查看
         * @author: lxy
         * @date: 2020-12-29 16:38:39
         */
        checkPic(row) 
        {
            const self = this;
            self.sign = 'check';
            let str = encryptCode({
                id: row.id
            })
            self.app_id = row.id;
            let data = { sign: request_sign, params: str }
            nprogressStart();
            self.$Loading.show()
            API.getExpBookInfo(data)
                .then((result) => {
                    nprogressClose();
                    self.$Loading.hide()
                    if (result.code > 0) 
                    {
                        self.fileList = [];
                        self.radio = result.info.status;
                        self.status = result.info.status;
                        self.infoVisible = true;
                        self.infoTitle = '查看申请'
                        self.expList = result.data;
                    }
                    else 
                    {
                        self.$Tips({
                            // 消息提示内容
                            message: result.msg,
                            // 消息提示类型（success-成功,warning-警告/失败）
                            messageType: 'warning',
                            displayTime: 1500
                        })
                    }
                })
                .catch(() => {
                    nprogressClose();
                    self.$Loading.hide()
                })
        },
        /**
         * @name: 批准
         * @author: lxy
         * @date: 2020-12-29 16:38:39
         */
        updatePic(row) 
        {
            const self = this;
            self.sign = 'update';
            let str = encryptCode({
                id: row.id
            })
            self.app_id = row.id;
            let data = { sign: request_sign, params: str }
            nprogressStart();
            self.$Loading.show()
            API.getExpBookInfo(data)
                .then((result) => {
                    nprogressClose();
                    self.$Loading.hide()
                    if (result.code > 0) {
                        self.fileList = [];
                        self.radio = result.info.status;
                        self.status = result.info.status;
                        self.infoVisible = true;
                        self.infoTitle = '批准申请(时长:两个月)'
                        self.expList = result.data;
                    }
                    else 
                    {
                        self.$Tips({
                            // 消息提示内容
                            message: result.msg,
                            // 消息提示类型（success-成功,warning-警告/失败）
                            messageType: 'warning',
                            displayTime: 1500
                        })
                    }
                })
                .catch(() => {
                    nprogressClose();
                    self.$Loading.hide()
                })
        },
        /**
         * @name: 确认更新关键词信息
         * @author: LXY
         * @date: 2021-03-01
         */
        confirmUpdate(state)
        {
            const self = this;
            if (self.radio == '2' && !self.groupFilePath) 
            {
                return this.$Tips({
                    message: '请上传试用申请学生名单',
                    messageType: 'warning',
                    displayTime: 1500
                })
            }
            let str = encryptCode({
                id: self.app_id,
                filePath: self.groupFilePath,
                state: state
            })
            let data = { sign: request_sign, params: str }
            nprogressStart();
            self.$Loading.show()
            API.reviewExpBook(data)
                .then((result) => {
                    nprogressClose();
                    self.$Loading.hide()
                    if (result.code > 0) {
                        this.fileList = [];
                        this.infoVisible = false;
                        this.getData()
                    }
                    else {
                        this.$Tips({
                            message: result.msg,
                            messageType: 'warning',
                            displayTime: 1500
                        })
                    }
                })
                .catch(() => {
                    nprogressClose();
                    self.$Loading.hide()
                })
        },
        /**
         * @name: 获取列表
         * @author: lxy
         * @date: 20210908
         */
        getData() 
        {
            try 
            {
                let self = this;
                let type = 0;
                if(self.activeName != 'first')
                {
                    type = 1;
                }
                // 请求参数
                let str = encryptCode({
                    search: self.input,
                    page: self.page,
                    pagesize: self.pageSize,
                    state: self.state,
                    type:type
                })
                let data = { sign: request_sign, params: str }
                nprogressStart();
                self.$Loading.show();
                self.$router.push({
                    path: "/manager/trialApplication",
                    query: {
                        params: str
                    },
                });
                // 获取列表请求
                API.getExpBookList(data)
                    .then((result) => {
                        nprogressClose();
                        self.$Loading.hide();
                        if (result.code > 0) 
                        {
                            self.schoolList = result.list
                            self.schoolListTotal = result.total - 0
                        }
                        else 
                        {
                            self.$Tips({
                                // 消息提示内容
                                message: result.msg,
                                // 消息提示类型（success-成功,warning-警告/失败）
                                messageType: 'warning',
                                displayTime: 1500
                            })
                        }
                    })
            }
            catch (error) 
            {
                // Message.error('系统错误') 
            }
        },
        /**
         * @name: 删除
         * @author: lxy
         * @date: 20210908
         */
        deletePic(row) 
        {
            try 
            {
                let self = this;
                // 请求参数
                let str = encryptCode({
                    id: row.id,
                    filePath: self.groupFilePath,
                    state: '0'
                })
                let data = { sign: request_sign, params: str }
                // 删除请求
                self.$confirm(`确认要拒绝${row.username ?? '该老师'}的试用申请吗?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    API.reviewExpBook(data)
                        .then((result) => {
                            if (result.code > 0) 
                            {
                                // Message.success('删除成功');
                                self.getData();
                                self.$Tips({
                                    // 消息提示内容
                                    message: result.msg,
                                    // 消息提示类型（success-成功,warning-警告/失败）
                                    messageType: 'success',
                                    displayTime: 1500
                                })
                            }
                            else 
                            {
                                self.$Tips({
                                    // 消息提示内容
                                    message: result.msg,
                                    // 消息提示类型（success-成功,warning-警告/失败）
                                    messageType: 'warning',
                                    displayTime: 1500
                                })
                            }
                        })
                }).catch(() => {
                    // Message.info('已取消');        
                });
            }
            catch (error) 
            {
                // Message.error('系统错误');
            }
        },
        /**
         * @name: 添加关键词
         * @author: lxy
         * @date: 20210908
         */
        confirmAdd() 
        {
            try 
            {
                let self = this;
                // 请求参数
                let data = {
                    id: self.schoolID,
                    pic: self.schoolPic
                };
                // 添加关键词请求
                API.updateSchoolLogo(data)
                    .then((result) => {
                        if (result.code > 0) {
                            self.logoVisible = false;
                            self.getData();
                            self.$Tips({
                                // 消息提示内容
                                message: result.msg,
                                // 消息提示类型（success-成功,warning-警告/失败）
                                messageType: 'success',
                                displayTime: 1500
                            })
                        }
                        else 
                        {
                            // Message.error('系统错误')
                            self.$Tips({
                                // 消息提示内容
                                message: result.msg,
                                // 消息提示类型（success-成功,warning-警告/失败）
                                messageType: 'warning',
                                displayTime: 1500
                            })
                        }
                    })
            }
            catch (error) 
            {
                // Message.error('系统错误') 
            }
        },
        /**
         * @name: 根据搜索条件,获取数据
         * @author: lxy
         * @date: 20210908
         */
        handleSearchColleague() 
        {
            let self = this;
            self.page = 1;
            self.$router.push({
                path: "/manager/trialApplication",
                query: {
                    search: self.input,
                    page: self.page,
                    state: self.state
                }
            });
            self.getData()
        },
    }
};